import React, { Component, useEffect } from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import bird1 from '../../assets/images/birds/bird1.png';
import bird2 from '../../assets/images/birds/bird2.png';

// experiences
import sundowner1 from '../../assets/images/experiences/sundowner1.jpg';

// transitionimage
import transitionimage from '../../assets/images/transition.jpg';

// artist images
import pcrc from '../../assets/images/artists/pcrc.jpg';
import gauley from '../../assets/images/artists/gauley.jpg';
import begum from '../../assets/images/artists/begum.jpg';
import sahil from '../../assets/images/artists/sahil.jpg';
import lifafa from '../../assets/images/artists/lifafa.jpg';
import arushi from '../../assets/images/artists/arushi.jpg';
import shantanu from '../../assets/images/artists/shantanu.jpg';
import noni from '../../assets/images/artists/noni.jpg';
import karshni from '../../assets/images/artists/karshni.jpg';
import unnayanaa from '../../assets/images/artists/unnayanaa.jpg';
import stalvart from '../../assets/images/artists/stalvart.jpg';
import carissa from '../../assets/images/artists/carissa.jpg';
import girls from '../../assets/images/artists/girls.jpg';
import kaleekarma from '../../assets/images/artists/kaleekarma.jpg';
import zag from '../../assets/images/artists/zag.jpg';
import digging from '../../assets/images/artists/digging.jpg';
import sindhi from '../../assets/images/artists/sindhi.jpg';
import mocity from '../../assets/images/artists/mocity.jpg';

const Page = styled.div`
  margin-top: 7.5vw;

  .siteWrapper {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-column: 1 / span 24;
    @media (min-width: 960px) {
      grid-column: 5 / span 16;
    }
    .experiences-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 960px) {
        grid-template-columns: repeat(1, 1fr);
      }
      grid-template-rows: masonry;
      grid-gap: 30px;
    }
  }
  .img {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  .text {
    margin: 60px 0;
    grid-column: 2 / span 22;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
  }
  header {
    grid-column: 2 / span 22;
    @media (min-width: 1100px) {
      grid-column: 5 / span 16;
    }

    padding: 3.5vw 0 0 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .back {
      margin-bottom: 20px;
      display: block;
      font-size: 1em;
    }
    .intro-text {
      grid-column: 1 / span 4;
      @media (min-width: 768px) {
        grid-column: 1 / span 3;
      }
      .title {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
      }
      .sub-heading {
        font-size: 1.2rem;
      }
      h1 {
        font-size: 3em;
        padding-bottom: 10px;
      }
      margin-bottom: 40px;
    }
    .top-right {
      grid-column: 6 / span 1;
      @media (min-width: 768px) {
        grid-column: 4 / span 6;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .circle-rotate {
        position: absolute;
        font-size: 0.7em;
        color: var(--olive);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        #circle-text {
          width: 130px;
          height: 130px;
          animation: spin 60s infinite linear;
          max-width: none;
        }
        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
      img {
        width: 60px;
      }
      .socials {
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 30px;
        @media (min-width: 768px) {
          margin-left: 20px;
          margin-top: 0px;
          margin-bottom: 0px;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            margin-right: 15px;
            padding: 0;
            list-style: none;
            display: inline-block;
            font-size: 1.2em;
            @media (min-width: 768px) {
              font-size: 1.5em;
            }
            a {
              color: var(--rust);
            }
          }
        }
      }
    }
    .right {
      grid-column: 1 / span 6;
      display: flex;
      flex-direction: row;
      @media (min-width: 768px) {
        flex-direction: row;
      }

      .img1 {
        flex: 1;

        img {
          display: block;
        }
      }
    }
  }

  // min-height: 100vh;
`;

const Bird2 = styled.div`
  position: absolute;
  left: -70px;
  top: 0%;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;

const Bird3 = styled.div`
  position: absolute;
  right: -70px;
  top: 400px;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;
const ExperiencesStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: masonry;
  grid-gap: 0;
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Experiences() {
  useEffect(() => {});
  return (
    <Page>
      <Bird2>
        <img src={bird2} alt="" />
      </Bird2>
      <Bird3>
        <img src={bird1} alt="" />
      </Bird3>
      <div className="SiteContainer">
        <div className="siteWrapper">
          <header>
            <div className="intro-text">
              <AniLink
                className="link back b-i"
                to="/artists"
                cover
                direction="down"
                activeClassName="active"
                bg={transitionImageDiv}
              >
                Back to Artists
              </AniLink>
              <h1>Unnayanaa</h1>
            </div>
            <div className="top-right">
              <div className="socials">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/UNNAYANAA/"
                      target="_blank"
                    >
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                  </li>

                  <li>
                    <a href="https://soundcloud.com/unnayanaa" target="_blank">
                      <FontAwesomeIcon icon={faSoundcloud} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right">
              <div className="img1">
                {' '}
                <img src={unnayanaa} alt="" />
              </div>
            </div>
          </header>

          <div className="text">
            <p>
              Unnayanaa constantly pushes boundaries as a DJ and music producer.
              Since 2006 he has seen a steady rise in popularity for his style
              of Afro, house and electronic music, both in India and around the
              world. His 2018 release “Taht Min Aini” was a resounding hit,
              while his tracks have paved their way to the playlists of DJs such
              as Solomun, Ame, Dixon and more, and been remixed by Toto
              Chiavetta, Osunlade and Trikk.
            </p>
            <p>
              With releases over the years on noteworthy labels such as
              Itstillmusic (Chicago), Papa Records (London), Rainy City Music
              (Manchester), Atypical Dopeness (New York), and having been
              recently signed as an artist on Toto Chiavetta's Italy-based label
              Borders Of Light, Unnayanaa has been making his mark on the
              industry, and is currently working on an EP compiled from his
              travels and recordings of Ethiopian and Congolese music.
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
}
